<template>
  <div>
    <div class="uk-flex uk-flex-between">
      <div>
        <h1 class="uk-text-left uk-margin-remove-bottom">{{ $t('Sidebar.InteractiveVideo') }}</h1>
      </div>

      <div class="uk-flex">
        <div class="uk-margin-small-right">
          <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
              <span class="uk-search-icon" uk-search-icon></span>
              <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
              <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
          </form>
        </div>
        <div>
          <button class="uk-button uk-button-primary uk-align-center uk-float-right" href="#modal-newstory" uk-toggle>
            <span uk-icon="plus-circle"></span>&nbsp;&nbsp;{{ $t('Actions.NewStory') }}</button>
        </div>
      </div>
      
    </div>
    <hr />
    <vue-good-table
      styleClass="vgt-table uk-table uk-table-small uk-table-striped uk-table-hover uk-table-responsive uk-margin-remove-bottom"
      :columns="tableHeaders"
      :rows="formattedInteractives"
      :line-numbers="false"
      v-if="interactives && interactives.length"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 25, 50, 100],
        dropdownAllowAll: true,
        setCurrentPage: 1,
        nextLabel: this.$t('Pages.PushNotifications.Next'),
        prevLabel: this.$t('Pages.PushNotifications.Prev'),
        rowsPerPageLabel: this.$t('Pages.PushNotifications.RowsPerPage'),
        ofLabel: 'of',
        pageLabel: this.$t('Pages.PushNotifications.Page'), // for 'pages' mode
        allLabel: this.$t('Pages.PushNotifications.All'),
      }"
      :sort-options="{
      enabled: true,
      initialSortBy: {field: 'name', type: 'asc'}
      }"
    >
      <div slot="emptystate" class="vgt-center-align vgt-text-disabled">
        {{ $t( 'Pages.InteractiveVideos.NoSearchData' ) }}
      </div>
      <template slot="table-row" slot-scope="record">
        <span v-if="record.column.field === 'name'">
          <a :href="`${ivEditor}/?load_story=${record.row.iv.id}`">{{ record.row.name }}</a>
        </span>
        <span class="uk-text-center" v-else-if="record.column.field === 'actions'">
          <a uk-icon="icon: pencil; ratio: 1;" @click.prevent="showIVDetails( record.row.iv.id )" class="uk-margin-small-right"></a>
          <span v-if="deletingList.includes( record.row.iv )" uk-spinner="ratio: 0.7" class="uk-margin-small-left" />
          <a v-else uk-icon="icon: trash; ratio: 1;" @click.prevent="deleteIV( record.row.iv )" class="uk-margin-small-left"></a>
        </span>
      </template>
    </vue-good-table>
    <div v-else-if="(!interactives || !interactives.length) && !fetching">
      <span class="uk-text-meta">{{$t( 'Pages.InteractiveVideos.NoData' )}}</span>
      <a class="uk-text-primary" href="#modal-newstory" uk-toggle>{{ $t('Actions.NewStory') }}</a>
    </div>
    <div v-if="fetching && !(interactives && interactives.length)">
      <span uk-spinner></span>
    </div>
    <div id="modal-newstory" uk-modal bg-close="false">
      <div class="uk-modal-dialog">
        <h3 class="uk-modal-header uk-text-left uk-margin-remove-bottom">{{ $t( 'Pages.InteractiveVideos.NewIV' ) }}
        </h3>
        <div class="uk-modal-body">
          <p class="uk-text-left uk-margin-remove-bottom">{{ $t( 'Pages.InteractiveVideos.Name' ) }} <span class="uk-text-danger">*</span></p>
          <div class="uk-inline uk-width-1-1">
            <input class="uk-input" id="iv-name" type="text" :class="{ 'invalid-border': !form.ivValid}"
              :placeholder="$t( 'Pages.InteractiveVideos.Placeholder.Name' )" v-model.trim="form.ivName"
              @keyup="validateInput( 'name' )">
            <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.ivValid" uk-icon="icon: warning" ></span>
            <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.ivValid">{{ $t( 'Generic.Errors.Min2Chars' ) }}</span>

          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" type="button">{{ $t( 'Actions.Cancel' ) }}</button>
          <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="saveNewIV();">{{ $t( 'Actions.Save' ) }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import { iv } from '@/app.config';
import Notification from '@/components/util/Notification';

export default {
  name: 'InteractiveVideoView',
  components: {
    VueGoodTable
  },
  data() {
    return {
      fetching: false,
      searchKey: this.$route?.query?.search || '',
      ivEditor: iv.editor, 
      tableHeaders : [
        {
          label: this.$t( 'Pages.InteractiveVideos.Name' ),
          field: 'name',
          thClass: 'uk-width-large uk-preserve-width',
          tdClass: 'uk-width-large uk-preserve-width uk-text-truncate',
          width: '35%',
        },
        {
          label: this.$t( 'Pages.InteractiveVideos.Created' ),
          field: 'created',
          thClass: 'uk-width-medium',
          tdClass: 'uk-width-medium',
          sortable: true
        },
        {
          label: this.$t( 'Pages.InteractiveVideos.TotalScenes' ),
          field: 'scenes',
          thClass: 'uk-width-small uk-text-center',
          tdClass: 'uk-width-small uk-text-center',
          width: '20%',
          sortable: true
        },
        {
          label: this.$t( 'Pages.InteractiveVideos.Hotspot' ),
          field: 'hotspots',
          thClass: 'uk-width-small uk-text-center uk-text-truncate',
          tdClass: 'uk-width-small uk-text-center',
          sortable: true
        },
        {
          label: this.$t( 'Actions.Actions' ),
          field: 'actions',
          thClass: 'uk-width-small uk-text-center',
          tdClass: 'uk-width-small uk-text-center',
          sortable: false
        }
      ],
      form: {},
      saving: false,
      deletingList: [],
      orgId: this.$route.params.id,
      loadData: true
    }
  },
  computed: {
    ...mapState({
      interactives: state => state.venom.org?.interactives?.edges || [],
      pageInfo: state => state.venom.org?.interactives?.pageInfo || {}
    }),
    formattedInteractives () {
      const interactives = this.interactives.filter(iv => iv.node?.name.toLowerCase().includes(this.searchKey.toLowerCase()))
      return interactives.map(item => {
        const iv = item.node
        return {
          name: iv.name,
          created: this.$d(new Date(iv.createdAt), 'long'),
          scenes: this.getSequenceCount(iv.scenes),
          hotspots: this.getHotspotCount(iv.hotspots),
          iv
        }
      })
    }
  },
  beforeDestroy () {
    this.loadData = false
    this.$store.dispatch('clearInteractives')
  },
  methods: {
    async fetchInteractives( isNextPage, pageInfo ) {
      const after = isNextPage ? ( pageInfo?.hasNextPage ? pageInfo?.endCursor : '' ) : '';
      if ( isNextPage && !after ) {
        return;
      }
      this.fetching = !isNextPage;
      const response = await this.$store.dispatch('fetchInteractives', {
        orgId: this.$route.params.id,
        first: 50,
        after,
        isNextPage
      });
      this.fetching = false;
      if (response?.pageInfo?.hasNextPage && this.loadData) {
        this.fetchInteractives(true, response.pageInfo)
      }
    },
    getSequenceCount( scenes ) {
      if ( scenes ) {
        return scenes.reduce( (count, scene) => count = scene.sequences.length, 0 );
      }
      return 0;
    },
    getHotspotCount( scenes ) {
      let totalHotspots = 0;
      if ( scenes ) {
        scenes.map(s => s.sequences.map(seq => {
          if ( seq.__typename === 'HotspotSceneSequence' )
            totalHotspots++;
        }));
      }
      return totalHotspots;
    },
    defaultFormData() {
      return {
        ivName: '',
        ivValid: true
      };
    },
    validateInput( field ) {
      let isValid = false;
      if ( field === 'name' && this.form.ivName.trim().length >= 2 ) {
        isValid = true;
      }
      this.form.ivValid = isValid;
      return isValid;
    },
    async saveNewIV() {
      const isValidName = this.validateInput( 'name' );
      if ( isValidName ) {
        this.saving = true;
        const ivId = await this.$store.dispatch( 'saveOrgNewIV', {
          ivData: {
            organization: this.orgId,
            name: this.form.ivName
          }
        } );
        this.saving = false;
        if( ivId ) {
          window.UIkit.modal( '#modal-newstory' ).$destroy(true);
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.InteractiveVideos.Notification.NewIVCreated' ) );
          this.showIVDetails( ivId );
        }
      }
    },
    showIVDetails( ivId ) {
      this.$router.push({ name: 'InteractiveVideoDetail', params: { id: this.orgId, ivId } });
    },
    deleteIV( iv ) {
      window.UIkit.modal.confirm( this.$t( 'DeleteModal.ConfirmDeleteAd', { name: iv.name }) ).then(async () => {
        this.deletingList.push( iv );
        await this.$store.dispatch('deleteInteractive', { interactive: iv.id });
        await this.fetchInteractives();
        Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.InteractiveVideos.Notification.DeletedSuccessful', { name: iv.name } ) );
      }, function () {
        // cancelled
      });
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  async mounted() {
    document.documentElement.scrollTop = 0
    this.form = this.defaultFormData();
    this.fetchInteractives( false );
    window.UIkit.util.on(document, 'beforeshow', '#modal-newstory', () => {
      this.form = this.defaultFormData();
    });
  }
}
</script>

<style lang="scss">
.tooltip {
  color: #e69393;
}
table.vgt-table, .vgt-input {
  background: none;
  border: 1px solid var(--border-color);
}
.vgt-inner-wrap {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.vgt-global-search {
  background: none;
  border: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vgt-global-search label {
  position: absolute;
  left: 10px;
}
.vgt-global-search__input .input__icon .magnifying-glass  {
  margin-left: 0px;
}
.vgt-global-search .vgt-input {
  width: 40%;
  margin-left: -40px;
  height: 40px;
  padding-left: 40px;
  color: var(--text-color);
}
.vgt-global-search .vgt-input::placeholder {
  color: var(--text-color);
}
table.vgt-table {
  font-size: 12px;
  border: 0px;
}
table.vgt-table td {
  border: 0px;
  color: var(--text-color);
}
.vgt-table thead th {
  background: none;
  color: var(--white-color);
  border: 0px;
  width: initial;
}
.vgt-table th.line-numbers {
  background: none;
  border-right: 1px solid var(--border-color);
}
.vgt-table th.sortable button:after {
  border-bottom: 5px solid var(--white-color);
}
.vgt-table th.sortable button:before {
  border-top: 5px solid var(--white-color);
}
.vgt-table .custom-center span {
  padding-left: 10px;
}
.vgt-wrap__footer {
  background: none;
  border: 0px;
}
.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  background: none;
  border: 1px solid var(--border-color);
  color: #909399;
  padding: 2px;
}
.vgt-wrap__footer .footer__row-count__label {
  font-size: 13px;
}

.vgt-table th.sortable button {
  width: auto;
  position: relative;
  margin-left: 5px;
  top: -.25rem;
}
  
</style>